import moment from 'moment'

export const isWithin14days = date => {
  const userCreationDate = moment(date).startOf('day')

  const fourteenDaysAgo = moment()
    .subtract(14, 'days')
    .startOf('day')

  return userCreationDate.isAfter(fourteenDaysAgo)
}

export const checklistIncentiveEligible = (user, membershipInfo) => {
  const { created_at, objective } = user
  const { billing_frequency, charge_frequency } = membershipInfo

  return (
    isWithin14days(created_at) &&
    objective === 'find_product' &&
    billing_frequency === 'monthly' &&
    charge_frequency === 1
  )
}
