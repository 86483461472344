import { BASE_PLAN_BILLING_FREQUENCY_OPTIONS } from '../constants/billing'

export const billingFrequencyFormatter = (value, field = 'name') => {
  const nameValMap = BASE_PLAN_BILLING_FREQUENCY_OPTIONS.reduce((map, obj) => {
    map[obj.value] = obj[field]
    return map
  }, {})

  return value ? nameValMap[value] : ''
}

export const billingFrequencyExpirationFormatter = (
  billingFrequency,
  chargeFrequency
) => {
  const data = BASE_PLAN_BILLING_FREQUENCY_OPTIONS.find(
    opt => opt.value === billingFrequency
  )

  if (!data) return

  return data.expirationFormatter(new Date(), chargeFrequency)
}

export const billingFrequencyIntervalFormatter = (
  billingFrequency,
  chargeFrequency = 1
) => {
  if (!billingFrequency) return ''

  if (chargeFrequency === 1) {
    return (
      billingFrequencyFormatter(billingFrequency, 'short') || billingFrequency
    ).toLowerCase()
  }

  return `${chargeFrequency} ${(
    billingFrequencyFormatter(billingFrequency, 'plural') || billingFrequency
  ).toLowerCase()}`
}
