// Function that compares strings ignoring special characters and upper/lower case
const compareStrings = (a, b) =>
  a.localeCompare(b, 'en', { sensitivity: 'base' }) === 0

// Given a category object and a raw category, return the category object if the name or any of the variants match
export const compareCategory = (category, rawCategory) =>
  compareStrings(category.name, rawCategory) ||
  (category.display_name &&
    compareStrings(category.display_name, rawCategory)) ||
  category.variants.find(variant => compareStrings(variant, rawCategory))

// Given a list of categories and a raw category, return the parent category object
export const getCalculatedCategory = (countryCategories, rawCategory) => {
  const validParams = rawCategory && countryCategories?.length

  const categoryFound = validParams
    ? countryCategories.find(category => compareCategory(category, rawCategory))
    : undefined

  // If category is found, return the display name or name, otherwise return the raw category
  const name = categoryFound?.display_name || categoryFound?.name || rawCategory

  return {
    name,
    supported: categoryFound?.supported || false
  }
}

// Filter by country, map the label and value, and sort alphabetically. Also filterSupported when true
export const getFormattedCountryCategories = (
  categories,
  country,
  filterSupported
) => {
  const countryCategories = categories?.[country] || []
  const filteredCategories = filterSupported
    ? countryCategories.filter(category => category.supported)
    : countryCategories

  return filteredCategories
    .map(category => ({
      label: category.display_name || category.name,
      value: category.name
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
}

export const getESFiltersValues = (countryCategories, categories) => {
  let result = []

  if (categories?.length) {
    categories.forEach(category => {
      const categoryMatched = countryCategories?.find?.(
        object => object.name === category
      )

      if (categoryMatched?.es_values?.length) {
        result = result.concat(categoryMatched.es_values)
      } else {
        result.push(category)
      }
    })
  }

  return result
}
