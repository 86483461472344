import includes from 'lodash/includes'
import { isAsin } from './amazon'

export const MISSING_PARAMS_TYPES = {
  COMPARISON_DATE_RANGE: 'Comparison date range',
  DATE_RANGE: 'Date range',
  MARKETPLACE: 'Marketplace',
  SEARCH_TERMS: 'Keyword or ASIN'
}

export const getMissingParamsTypes = missingParams => {
  // sometimes we have more than one missing type, like when asking for keyword data
  // the chatbot will ask for both country and search_term
  const missingParamsTypes = []
  if (includes(missingParams, 'comparison_start_date', 'comparison_end_date')) {
    missingParamsTypes.push(MISSING_PARAMS_TYPES.COMPARISON_DATE_RANGE)
  }
  if (includes(missingParams, 'start_date', 'end_date')) {
    missingParamsTypes.push(MISSING_PARAMS_TYPES.DATE_RANGE)
  }
  if (includes(missingParams, 'country')) {
    missingParamsTypes.push(MISSING_PARAMS_TYPES.MARKETPLACE)
  }
  if (includes(missingParams, 'search_terms')) {
    missingParamsTypes.push(MISSING_PARAMS_TYPES.SEARCH_TERMS)
  }
  return missingParamsTypes
}

export const getMissingParamsTrackingValue = (type, missingParamsValues) => {
  let value = ''
  let finalType = type
  if (
    type === MISSING_PARAMS_TYPES.COMPARISON_DATE_RANGE ||
    type === MISSING_PARAMS_TYPES.DATE_RANGE
  ) {
    value = missingParamsValues.dateRangeType
  }
  if (type === MISSING_PARAMS_TYPES.MARKETPLACE) {
    value = missingParamsValues.country
  }
  if (type === MISSING_PARAMS_TYPES.SEARCH_TERMS) {
    value = missingParamsValues.search_terms
    finalType = isAsin(value) ? 'ASIN' : 'Keyword'
  }
  return { value, finalType }
}
