import {
  CHINESE_LANGUAGE_CHECKOUT_LOCALES,
  USD_CURRENCY_CODE,
  EUR_CURRENCY_CODE,
  INR_CURRENCY_CODE,
  JPY_CURRENCY_CODE
} from 'constants/account'
import {
  DE_LANGUAGE_CODE,
  ES_LANGUAGE_CODE,
  FR_LANGUAGE_CODE,
  IT_LANGUAGE_CODE
} from 'constants/languages'
import {
  EU_PLANS,
  IN_PLANS,
  JP_PLANS,
  US_PLANS,
  CN_PLANS
} from 'constants/plan_details'

export const getEuLanguageCode = selectedLanguage => {
  switch (selectedLanguage) {
    case ES_LANGUAGE_CODE:
      return ES_LANGUAGE_CODE
    case DE_LANGUAGE_CODE:
      return DE_LANGUAGE_CODE
    case IT_LANGUAGE_CODE:
      return IT_LANGUAGE_CODE
    case FR_LANGUAGE_CODE:
    default:
      return FR_LANGUAGE_CODE
  }
}

export const getCheckoutUrlOptions = (checkoutLocale, currency) => {
  if (
    CHINESE_LANGUAGE_CHECKOUT_LOCALES.includes(checkoutLocale?.toUpperCase())
  ) {
    return CN_PLANS
  }

  switch (currency?.toLowerCase()) {
    case EUR_CURRENCY_CODE:
      return EU_PLANS
    case INR_CURRENCY_CODE:
      return IN_PLANS
    case JPY_CURRENCY_CODE:
      return JP_PLANS
    case USD_CURRENCY_CODE:
    default:
      return US_PLANS
  }
}

export const legacyGroupToNewGroupMap = group => {
  switch (group?.toLowerCase().replace(' ', '')) {
    case 'basic':
    case 'starter':
      return 'Starter'
    case 'suite':
    case 'growthaccelerator':
      return 'Growth Accelerator'
    case 'pro':
    case 'brandowner':
      return 'Brand Owner'
    default:
      return 'Next Plan'
  }
}

export const currentGroupToUpgradeGroupMap = group => {
  switch (group?.toLowerCase().replace(' ', '')) {
    case 'basic':
    case 'starter':
      return 'Growth Accelerator'
    case 'suite':
    case 'growthaccelerator':
      return 'Brand Owner'
    default:
      return 'Next Plan'
  }
}

export const currentGroupToNextGroup = group => {
  switch (group?.toLowerCase()) {
    case 'basic':
    case 'starter':
      return 'growthaccelerator'
    case 'suite':
    case 'growthaccelerator':
    case 'pro':
      return 'brandowner'
    default:
      return 'Next Plan'
  }
}
