import moment, { isMoment } from 'moment'
import {
  formatDateTick,
  addFormattedDate
} from 'helpers/advertisement/formatters'
import {
  sumKeys,
  maxKeys,
  avgKeys,
  avgRating,
  avgKeysCurrency,
  sumObjectKeys
} from './objects'
import { filterArray, getAverage, getSum } from './arrays'
import cloneDeep from 'lodash/cloneDeep'

export const formatDateAxis = val =>
  (isMoment(val) ? val : moment(val)).utc().format('YYYY-MM')

export const formatDateAxisMonthDay = (val, format) =>
  (isMoment(val) ? val : moment(val)).utc().format(format || 'MM-DD')

export const formatDateAxisMonthDayFull = (val, format) =>
  (isMoment(val) ? val : moment(val, format)).utc().format('MMM DD')

export const formatDateAxisMonthDayYear = val =>
  (isMoment(val) ? val : moment(val)).utc().format('YYYY-MM-DD')

export const formatDateAxisMonthFull = (val, format) =>
  (isMoment(val) ? val : moment(val, format)).utc().format('MMM')

export const formatDateAxisRange = (startDate, endDate, format) => {
  return `${formatDateAxisMonthDayFull(
    startDate,
    format
  )} - ${formatDateAxisMonthDayFull(endDate, format)}`
}

export const getXAxisInterval = (dataLength, numOfSections) => {
  if (!dataLength || dataLength <= numOfSections) {
    return 0
  }

  return Math.round(dataLength / numOfSections)
}

export const groupDataByDay = (data, format) => {
  return data.reduce((accumulator, currentData) => {
    const date = moment(currentData.date, format)
    const year = date.year()
    const month = date.month()
    const day = date.date()

    const key = `${year}-${month + 1}-${day}` // month + 1 because moment.js month starts from 0

    if (accumulator[key]) {
      accumulator[key].push(currentData)
    } else {
      accumulator[key] = [currentData]
    }

    return accumulator
  }, {})
}

export const groupDataByWeek = (data, format) => {
  const result = data.reduce((accumulator, currentData) => {
    const date = moment.utc(currentData.date, format)
    let year = date.year()
    const month = date.month()
    const week = date.week()

    let key = `${year}-${week}`

    // if month is 0 (i.e January) and week is bigger than 52 then we should group this with dates in the previous year
    // if month is 11 (Dec) and week is 1 then we should group this with dates in the new year
    if (month === 0 && week >= 52) {
      year -= 1
      key = `${year}-${week}`
    } else if (month === 11 && week === 1) {
      year += 1
      key = `${year}-${week}`
    }

    if (accumulator[key]) {
      accumulator[key].push(currentData)
    } else {
      accumulator[key] = [currentData]
    }

    return accumulator
  }, {})

  return result
}

/**
 * Deletes the first and last groupings if they are not full weeks
 */
export const groupDataByWeekAndTrim = (data, format) => {
  const groupedData = groupDataByWeek(data, format)
  const clonedData = cloneDeep(groupedData)

  const weeks = Object.keys(clonedData)
  const firstWeek = weeks[0]
  const lastWeek = weeks[weeks.length - 1]

  if (clonedData[firstWeek]?.length !== 7) delete clonedData[firstWeek]
  if (clonedData[lastWeek]?.length !== 7) delete clonedData[lastWeek]

  return clonedData
}

export const groupDataByMonth = (data, format) => {
  return data.reduce((accumulator, currentData) => {
    const date = moment.utc(currentData.date, format)
    const year = date.year()
    const month = date.month()

    const key = `${year}-${month}`

    if (accumulator[key]) {
      accumulator[key].push(currentData)
    } else {
      accumulator[key] = [currentData]
    }

    return accumulator
  }, {})
}

export const groupDataByYear = (data, format) => {
  return data.reduce((accumulator, currentData) => {
    const year = moment(currentData.date, format).year()

    if (accumulator[year]) {
      accumulator[year].push(currentData)
    } else {
      accumulator[year] = [currentData]
    }

    return accumulator
  }, {})
}

export const isLongerThanOneQuarter = data => {
  if (!data || data.length === 0) return false
  const { date } = data[0]
  const endDate = data[data.length - 1].date
  const start = moment(date)
  const end = moment(endDate)
  const numDays = moment.duration(end.diff(start)).asDays()
  return numDays > 90
}

export const getDateRange = ({
  data,
  interval,
  format,
  forcedInterval = undefined
}) => {
  const date = moment.utc(data[0].date, format)
  const endDate = moment.utc(data[data.length - 1].date, format)

  let intervalStartDate = date.clone()
  let intervalEndDate = endDate.clone()

  let isPartialData = false

  if (interval === 'weekly') {
    const startIsSunday = date.day() === 0 // Sunday
    const endIsSaturday = endDate.day() === 6 // Saturday

    if (!startIsSunday) intervalStartDate = date.clone().day(0)
    if (!endIsSaturday) intervalEndDate = date.clone().day(6)
    if (data.length < 7) isPartialData = true
  } else if (interval === 'monthly') {
    const startOfMonth = date.clone().startOf('month')
    const endOfMonth = endDate.clone().endOf('month')

    if (!date.isSame(startOfMonth)) intervalStartDate = startOfMonth
    if (!endDate.isSame(endOfMonth)) intervalEndDate = endOfMonth

    const daysInMonth = moment(date, format).daysInMonth()
    if (data.length < daysInMonth) isPartialData = true
  }

  return {
    date: date.format(format), // first date of the given data
    endDate: interval === 'daily' ? null : endDate.format(format), // last date of the given data, not needed for daily data
    formattedDate: formatDateTick(
      date,
      endDate,
      forcedInterval || interval,
      format
    ),
    intervalStartDate: intervalStartDate.format(format), // start date of the interval for given data, if data is monthly and in the 11th month, start date would be Nov 1st
    intervalEndDate: intervalEndDate.format(format), // end date of the interval for given data, if data is monthly and in the 11th month, end date would be Nov 30th
    isPartialData
  }
}
/**
 *
 * @param {Aarray} data
 * @param {string} interval - daily, weekly, monthly, yearly
 * @param {*} groupingType - sum, avg, max, avgCurrency
 * @param {Array} keys - keys to access from the incoming data and include in the returned grouped data
 * @param {boolean} groupDaily
 * @param {string} format - date format of the incoming data
 * @param {boolean} skipNullValues - skip null values in the sum
 * @returns {Array} - formatted data
 * [
 *    {
 *      date: string,
 *      formattedDate: string,
 *      endDate: string,
 *      isPartialData: boolean,
 *      {...grouped data based on groupingType and keys},
 *      rawGroupData: [...data]
 *    },
 *   ...
 * ]
 */
export const formatChartDataByInterval = (
  data,
  interval,
  groupingType,
  keys = [],
  groupDaily = false,
  format = undefined,
  skipNullValues = false
) => {
  if (!data || data.length === 0) return []

  let groupedData = {}

  if (interval === 'daily' && groupDaily) {
    groupedData = groupDataByDay(data, format)
  } else if (interval === 'daily') {
    return addFormattedDate(data, interval)
  }

  if (interval === 'weekly') {
    groupedData = groupDataByWeek(data, format)
  } else if (interval === 'monthly') {
    groupedData = groupDataByMonth(data, format)
  } else if (interval === 'yearly') {
    groupedData = groupDataByYear(data, format)
  }

  if (groupingType) {
    let localKeys = keys

    if (!localKeys.length) {
      localKeys = Object.keys(data[0]).filter(key => key !== 'date')
    }

    return Object.values(groupedData).map(group => {
      const dateRange = getDateRange({ data: group, interval, format })

      let mergedGroup = {}

      switch (groupingType) {
        case 'avg':
          mergedGroup = avgKeys(group, localKeys, skipNullValues)
          break
        case 'avgCurrency':
          mergedGroup = avgKeysCurrency(group, localKeys)
          break
        case 'max':
          mergedGroup = maxKeys(group, localKeys)
          break
        default:
          mergedGroup = sumKeys(group, localKeys)
      }

      mergedGroup.rawGroupData = group

      return {
        ...dateRange,
        ...mergedGroup
      }
    })
  }

  const objKeys = Object.keys(
    data.reduce((accumulator, current) => ({ ...accumulator, ...current }), {})
  )

  const keysToGetSum = filterArray(objKeys, [
    'unitSales',
    'revenue',
    'revenue1p',
    'revenue3p',
    'revenueTotal',
    'unitSales3p',
    'unitSales1p',
    'unitSalesTotal',
    'oneToThree',
    'fourToTen',
    'elevenToTwenty',
    'twentyOneToOneHundred',
    'buyBoxSeller'
  ])

  const keysToGetMax = filterArray(objKeys, ['brands', 'asins'])
  const keysToGetAvg = filterArray(objKeys, [
    'avgReviews',
    'reviewCount',
    'newOfferCount',
    'usedOfferCount',
    'salesRank'
  ])
  const keysToGetAvgRating = filterArray(objKeys, ['starRating'])
  const keysToGetAvgCurrency = filterArray(objKeys, [
    'avgPrice',
    'usedPrice',
    'buyBoxPrice',
    'amazonPrice'
  ])

  return Object.values(groupedData).map(group => {
    const dateRange = getDateRange({ data: group, interval, format })

    const sumGroup = sumKeys(group, keysToGetSum)
    const maxGroup = maxKeys(group, keysToGetMax)
    const avgGroup = avgKeys(group, keysToGetAvg)
    const avgRatingGroup = avgRating(group, keysToGetAvgRating)
    const avgCurrencyGroup = avgKeysCurrency(group, keysToGetAvgCurrency)

    return {
      ...dateRange,
      ...sumGroup,
      ...maxGroup,
      ...avgGroup,
      ...avgRatingGroup,
      ...avgCurrencyGroup
    }
  })
}

export const formatAnnotationChartDataByInterval = (data, interval) => {
  const format = 'YYYY-MM-DD'

  if (data.length === 0) return []

  let groupedData = {}

  if (interval === 'daily') {
    return data
  }

  if (interval === 'weekly') {
    groupedData = groupDataByWeek(data, format)
  } else if (interval === 'monthly') {
    groupedData = groupDataByMonth(data, format)
  }

  const objKeys = Object.keys(
    data.reduce((accumulator, current) => ({ ...accumulator, ...current }), {})
  )

  const keysToGetObjectSum = filterArray(objKeys, [
    'sellerName',
    'imageUrl',
    'lightningDeal',
    'starRating',
    'amazonChoiceKeyword',
    'name',
    'category',
    'featureBullets'
  ])

  return Object.values(groupedData).map(group => {
    const dateRange = getDateRange({ data: group, interval, format })

    const sumObjectGroup = sumObjectKeys(group, keysToGetObjectSum)
    const annotationsData = group.filter(
      groupData => Object.keys(groupData).length > 1
    )

    const chartData = {
      ...dateRange,
      ...sumObjectGroup
    }

    if (annotationsData.length) {
      chartData.annotations = annotationsData
    }

    return chartData
  })
}

export const compareImageUrls = (url1, url2) => {
  try {
    const newUrl = new URL(url1)
    const oldUrl = new URL(url2)
    return newUrl.pathname === oldUrl.pathname
  } catch (error) {
    // We can ignore this conversion error
  }
}

// Takes an array of objects, e.g. [{ date: string, ...key/value attribute pairs }]
// and returns an array of changes, e.g. [{ date: string, [key]: { oldValue, newValue } }]
export const buildChangelog = (data, excludedAttrs = []) => {
  return data.map((item, i) => {
    const { date, ...attributes } = item
    const changelogItem = { date }
    // Skip the first day because it has nothing to be compared to
    if (i === 0) return changelogItem

    const previousItem = { ...data[i - 1] }
    delete previousItem.date

    // Need to pull all the unique attributes from both objects to catch
    // transitions from valid value to undefined and back
    const intersectingAttributes = new Set([
      ...Object.keys(attributes),
      ...Object.keys(previousItem)
    ])

    intersectingAttributes.forEach(attr => {
      const newValue = item[attr]
      // when an attribute is excluded it shouldn't have a comparison between old and new values
      const oldValue = excludedAttrs.includes(attr)
        ? undefined // needs to be undefined, not null
        : previousItem[attr]

      if (Array.isArray(oldValue) && Array.isArray(newValue)) {
        if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
          changelogItem[attr] = { oldValue, newValue }
        }
      } else if (oldValue !== newValue) {
        if (attr === 'imageUrl') {
          // Special case where URLs have different hosts but same path
          const imagesAreEqual = compareImageUrls(oldValue, newValue)
          if (!imagesAreEqual) {
            changelogItem[attr] = { oldValue, newValue }
          }
        } else {
          changelogItem[attr] = { oldValue, newValue }
        }
      }
    })
    return changelogItem
  })
}

// Takes array of changelog data for Daily view,
// e.g. [{ date: '2021-06-13', starRating: { oldValue: 4.9, newValue: 4.8 } }, { date: '2021-06-14' }],
// adds current index to each item and then filter array to only leave items that have some annotations =>
// e.g. [{ date: '2021-06-13', index: 0, starRating: { oldValue: 4.9, newValue: 4.8 } }]
// Index and date values are present in every annotation object, so we filter only those, which contain extra information
export const buildDailyListingsAnnotationsChangelog = data =>
  data
    ?.map((item, i) => ({ ...item, index: i }))
    .filter(item => Object.keys(item).length > 2)

// Takes array of changelog data for Weekly and Monthly views,
// e.g. [
//        { date: '2021-06-13', endDate: '2021-06-20', isPartialData: false, annotations: [date: '2021-06-14', starRating: { oldValue: 4.9, newValue: 4.8 }}], starRating: 1 },
//        { date: '2021-06-21', endDate: '2021-06-28', isPartialData: false }
//      ],
// adds current index to each item and then filter array to only leave items that have some annotations =>
// e.g. [{
//         date: '2021-06-13',
//         endDate: '2021-06-20',
//         isPartialData: false,
//         index: 0,
//         annotations: [{ date: '2021-06-14', starRating: { oldValue: 4.9, newValue: 4.8 }}],
//         starRating: 1
//      }]

export const buildListingsAnnotationsChangelog = data =>
  data
    ?.map((item, i) => ({ ...item, index: i }))
    .filter(item => item.annotations)

export const filterIsPartialDateRange = data =>
  data.filter(
    (item, index) =>
      (index !== 0 && index !== data.length - 1) || !item.isPartialData
  )
export const showRefLineForVariantsChange = (
  startDate,
  endDate,
  dateOfSalesEstimateChange
) => {
  if (startDate && endDate) {
    return (
      startDate.isSameOrBefore(dateOfSalesEstimateChange) &&
      endDate.isSameOrAfter(dateOfSalesEstimateChange)
    )
  }
  return false
}

export const findClosestDataToVariantsChange = (
  data,
  dateOfSalesEstimateChange
) => {
  for (let i = 0; i <= data.length - 1; i += 1) {
    const { date } = data[i]
    if (moment(date).isSame(dateOfSalesEstimateChange)) {
      return date
    }
    if (moment(date).isAfter(dateOfSalesEstimateChange)) {
      return data[i - 1] ? data[i - 1].date : date
    }
  }
  return data[data.length - 1].date
}

/**
 * Equalizes data keys all over the array of objects. Given an array of objects with different keys, this function equalizes all the objects to have the same format (same keys)
 *
 * @param {array} data object array
 * @returns equalized object array, where every item has the same keys
 */
export const equalizeDataKeys = data => {
  if (data == null) {
    return data
  }
  const keyList = data
    .map(val => Object.keys(val))
    .reduce((acum, curr) => Array.from(new Set([...acum, ...curr])), [])

  return data.map(item => {
    // initiates the array with null for every value
    const convertedData = keyList.reduce((acum, val) => {
      return { ...acum, [val]: null }
    }, {})
    Object.keys(item).forEach(key => {
      convertedData[key] = item[key]
    })
    return convertedData
  })
}

/**
 * Takes the chart data and creates a trend line for the passed graph keys
 * @param data
 * @param xKey
 * @param yKey
 * @returns {{}|{calcY: (function(*)), yStart: number, slope: number}}
 */
export const createTrend = (data, xKey, yKey) => {
  if (!data || !data.length || !xKey || !yKey) {
    return {}
  }

  const xData = data.map(value => value[xKey])
  const yData = data.map(value => value[yKey])

  const xAvg = getAverage(xData)
  const yAvg = getAverage(yData)

  // Subtract X or Y avg from corresponding axis value
  const xMinusXAvg = xData.map(val => val - xAvg)
  const yMinusYAvg = yData.map(val => val - yAvg)

  const xMinusXAvgSq = xMinusXAvg.map(val => val ** 2)

  const xy = []
  for (let x = 0, l = data.length; x < l; x += 1) {
    xy.push(xMinusXAvg[x] * yMinusYAvg[x])
  }

  const xySum = getSum(xy)

  const slope = xySum / getSum(xMinusXAvgSq)
  // start of the slope on the Y axis
  const yStart = yAvg - slope * xAvg

  return {
    slope,
    yStart,
    calcY: y => yStart + slope * y
  }
}
