// This file is a home for things I couldn't find a better home for, please use sparingly
import { isValidValue } from './values'

/**
 * Calculates new state for a True/False toggle
 *
 * This prioritizes the 'newState' param:
 * Pass in true or false to force the state, otherwise, it toggles the current state
 */
export const getShowState = (currentState, newState) => {
  return isValidValue(newState) ? newState : !currentState
}
